import { PropsWithChildren, ReactNode } from 'react';
import cx from 'classnames';

interface UiPageContainerProps extends PropsWithChildren {
  className?: string;
  topNavbar?: ReactNode;
  contained?: boolean;
}

export default function UiPageContainer({
  className,
  topNavbar,
  contained,
  children,
}: UiPageContainerProps) {
  if (topNavbar) {
    return (
      <div className={cx('w-full px-20 pb-16 pt-0', className)}>
        <div className="mb-0">{topNavbar}</div>
        {children}
      </div>
    );
  }

  if (contained) {
    return (
      <div
        className={cx(
          'flex h-full w-full max-w-7xl flex-col gap-4 px-8 py-4 sm:container sm:mx-auto lg:px-16',
          className,
        )}
      >
        {children}
      </div>
    );
  }

  return <div className={cx('w-full px-20 py-16', className)}>{children}</div>;
}
