interface IconCloseProps {
  variant?: 'contained';
  className?: string;
}

export default function IconClose({ variant, className }: IconCloseProps) {
  switch (variant) {
    case 'contained':
      // The close icon inside of a circle
      return (
        <svg
          className={className}
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 2C4.7 2 2 4.7 2 8C2 11.3 4.7 14 8 14C11.3 14 14 11.3 14 8C14 4.7 11.3 2 8 2ZM10.1 9.05C10.3899 9.33995 10.3899 9.81005 10.1 10.1V10.1C9.81005 10.3899 9.33995 10.3899 9.05 10.1L8.17678 9.22678C8.07915 9.12915 7.92085 9.12915 7.82322 9.22678L6.95 10.1C6.66005 10.3899 6.18995 10.3899 5.9 10.1V10.1C5.61005 9.81005 5.61005 9.33995 5.9 9.05L6.77322 8.17678C6.87085 8.07915 6.87085 7.92085 6.77322 7.82322L5.9 6.95C5.61005 6.66005 5.61005 6.18995 5.9 5.9V5.9C6.18995 5.61005 6.66005 5.61005 6.95 5.9L7.82322 6.77322C7.92085 6.87085 8.07915 6.87085 8.17678 6.77322L9.05 5.9C9.33995 5.61005 9.81005 5.61005 10.1 5.9V5.9C10.3899 6.18995 10.3899 6.66005 10.1 6.95L9.22678 7.82322C9.12915 7.92085 9.12915 8.07915 9.22678 8.17678L10.1 9.05Z"
            fill="currentColor"
          />
        </svg>
      );
    default:
      // Just the close icon
      return (
        <svg
          className={className}
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.28033 5.21967C5.98744 4.92678 5.51256 4.92678 5.21967 5.21967C4.92678 5.51256 4.92678 5.98744 5.21967 6.28033L6.93934 8L5.21967 9.71967C4.92678 10.0126 4.92678 10.4874 5.21967 10.7803C5.51256 11.0732 5.98744 11.0732 6.28033 10.7803L8 9.06066L9.71967 10.7803C10.0126 11.0732 10.4874 11.0732 10.7803 10.7803C11.0732 10.4874 11.0732 10.0126 10.7803 9.71967L9.06066 8L10.7803 6.28033C11.0732 5.98744 11.0732 5.51256 10.7803 5.21967C10.4874 4.92678 10.0126 4.92678 9.71967 5.21967L8 6.93934L6.28033 5.21967Z"
            fill="currentColor"
          />
        </svg>
      );
  }
}
