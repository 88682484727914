/**
 * Default Tailwind breakpoints from https://github.com/tailwindlabs/tailwindcss/blob/master/stubs/config.full.js
 */
export default {
  xs: '320px',
  sm: '640px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
  '2xl': '1600px',
};

export const values = {
  xs: 320,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1600,
};
