import screens from '@/themes/tokens/screens';
import { useMediaQuery } from './usehooks-ts';

export function useScreens() {
  const isScreenSm = useMediaQuery(`(max-width: ${screens.sm})`);

  return {
    isScreenSm,
  };
}
