import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import routes from './router/employer';
import { EMPLOYER_BASE_PATH } from './utils/employer/constants';

const router = createBrowserRouter(routes, {
  basename: EMPLOYER_BASE_PATH,
});

function App() {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
