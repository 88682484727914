import {
  campaignV1,
  campaignV1NonDraft,
  recurringV1Schedule,
} from '@fountain/wx-api-clients/generated/communicate';

export type RecurringCampaign = campaignV1NonDraft & {
  schedule: recurringV1Schedule;
};

export default function isRecurringCampaign(
  campaign: campaignV1,
): campaign is RecurringCampaign {
  if (campaign?.schedule?.type === 'RecurringV1') {
    return true;
  }

  return false;
}
