import { Status } from '@/models/table.schema';

export const TABLE_STATUS_TAB: { [key in Status]: Status } = {
  active: 'active',
  all: 'all',
  archived: 'archived',
  complete: 'complete',
  completed: 'completed',
  draft: 'draft',
  paused: 'paused',
  published: 'published',
  scheduled: 'scheduled',
  applied: 'applied',
  hired: 'hired',
  rejected: 'rejected',
};
